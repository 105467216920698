import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout/Layout";
import Heading from "../components/PageContainer/Heading";
import MasonryCard from "../components/Masonry/MasonryCard";
import { motion } from "framer-motion";
import Seo from "../components/seo";

const nyheter = ({ data: { posts } }) => {
  const news = posts.edges;
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        type: "tween",
      },
    },
  };

  const childItem = {
    hidden: { opacity: 0, y: -10 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <Layout>
      <Seo title={"Nyheter"} description={"UÅ Padel AB Senaste nyheter"} />
      <div className="container pt-[86px] mt-[60px] lg:mt-24 ">
        <Heading title={"Nyheter"} centerXs />

        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 pt-6 pb-12"
        >
          {news.map((item, index) => (
            <motion.div variants={childItem} key={index}>
              <MasonryCard
                title={item.node?.title}
                text={item.node?.nyhet?.text}
                published={item.node?.date}
                image={item?.node?.nyhet?.image}
                fullHeight
              >
                <Link to={`/nyheter/${item.node?.slug}`}>Läs mer</Link>
              </MasonryCard>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </Layout>
  );
};

export default nyheter;

export const pageQuery = graphql`
  query NewsPage {
    posts: allWpPost(sort: { order: DESC, fields: date }) {
      edges {
        node {
          title
          slug
          date(formatString: "LL", locale: "sv")
          nyhet {
            text
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;
